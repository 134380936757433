import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { Platform, Events } from '@ionic/angular';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, BehaviorSubject, from, of } from 'rxjs';
import { take, map, switchMap, shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LocaleService } from './locale.service';

const helper = new JwtHelperService();
const TOKEN_KEY = 'jwt-token';
const API_KEY = environment.apiKey;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: Observable<any>;
  private userData = new BehaviorSubject(null);
  private authToken = new BehaviorSubject(null);
  public locale: string = null;


  private loginUrl: string = null;
  private registerUrl: string = null;
  private recoveryUrl: string = null;
  private changePasswordUrl: string = null;
  private appRecoverUrl: string = null;
  private allowInsecurePasswords = environment.allowInsecurePasswords;

  constructor(
    private storage: Storage,
    private http: HttpClient,
    private plt: Platform,
    private events: Events,
    private router: Router,
    private localeService: LocaleService
  ) {
      this.loadStoredToken();
  }

  loadStoredToken() {
    let platformObs = from(this.plt.ready());

    this.user = platformObs.pipe(
      switchMap(() => from(this.storage.get(TOKEN_KEY))),
      map(token => {
        if (token) {
          let decoded = helper.decodeToken(token);
          if (helper.isTokenExpired(token)) {
            return null;
          }
          this.authToken.next(token);
          this.userData.next(decoded);
          this.events.publish('userloggedin', decoded);
          return true;
        } else {
          return null;
        }
      }),
      shareReplay(1)
    );
  }

  getAuthToken() {
    return this.authToken.getValue();
  }

  login(credentials: {username: string, pw: string}): Observable<any> {
    this.loginUrl = `${environment.apiUrl}/login_check?l=${this.localeService.getLocale()}`;

    return this.http.post<any>(this.loginUrl, {_username: credentials.username, _password: credentials.pw, _api_key: API_KEY}).pipe(
      map(response => {
        return response.token;
      }),
      switchMap(token => {
        let decoded = helper.decodeToken(token);
        this.authToken.next(token);
        this.userData.next(decoded);
        let storageObs = from(this.storage.set(TOKEN_KEY, token));
        this.events.publish('loginsuccesfull', decoded);
        return storageObs;
      })
    );
  }

  register(username: string, pw: string, name: string, surname: string, email: string): Observable<any> {
    this.registerUrl = `${environment.apiUrl}/register?l=${this.localeService.getLocale()}`;
    return this.http.post<any>(this.registerUrl, {
      _api_key: API_KEY,
      _username: username,
      _password: pw,
      _name: name,
      _surname: surname,
      _email: email
    }).pipe(
      map(response => {
        return response;
      }),
      switchMap(
        () => this.login({username, pw})
      )
    );
  }

  // recovery(username: string, email: string): Observable<any> {
  recovery(username: string): Observable<any> {
    this.recoveryUrl = `${environment.apiUrl}/recovery?l=${this.localeService.getLocale()}`;
    this.appRecoverUrl = `${environment.appUrl}/${this.localeService.getLocale()}/change-password`;
    return this.http.post<any>(this.recoveryUrl, {
      _url: this.appRecoverUrl,
      _username: username,
      // _email: email,
      _api_key: API_KEY,
      _mail_transport: environment.mailTransport,
      _mail_brand_name: environment.brandName
    }).pipe(
      map(response => {
        return response.message ? response.message : '';
      })
    );
  }

  changePassword(password: string, token: string): Observable<any> {
    this.changePasswordUrl = `${environment.apiUrl}/change-password?l=${this.localeService.getLocale()}`;
    return this.http.post<any>(this.changePasswordUrl, {
      _password: password,
      _token: token,
      _api_key: API_KEY
    }).pipe(
      map(response => {
        return response.message ? response.message : '';
      })
    );
  }

  getUser() {
    return this.userData.getValue();
  }

  logout() {
    localStorage.removeItem('flyingSocioSanitaryProfile');
    this.storage.remove(TOKEN_KEY).then(() => {
      this.router.navigateByUrl('/');
      this.userData.next(null);
    });
  }

  isValidPassword(plainPassword) {
    if (this.allowInsecurePasswords || !plainPassword) {
      return true;
    }
    return /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/.test(plainPassword);
  }
}
